var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$account.isFullAdmin || this.$account.permissions.canViewAgentList === true)?_c('v-sheet',{attrs:{"color":"white"}},[_c('v-data-table',{attrs:{"single-expand":"","show-expand":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.agentList,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([(this.$account.isFullAdmin || this.$account.permissions.canViewAgentDetails === true)?{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/admin/edit/" + (item.id))}},[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}}:{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(_vm.canAssignRoleToAgent === true)?_c('v-select',{attrs:{"hide-details":"","dense":"","items":_vm.roles,"item-value":"id","item-text":"name","rounded":"","filled":""},on:{"change":function($event){return _vm.updateAgentRole(item)}},model:{value:(item.role.id),callback:function ($$v) {_vm.$set(item.role, "id", $$v)},expression:"item.role.id"}}):_c('v-select',{attrs:{"hide-details":"","dense":"","items":_vm.roles,"item-value":"id","item-text":"name","rounded":"","filled":"","disabled":""},on:{"change":function($event){return _vm.updateAgentRole(item)}},model:{value:(item.role.id),callback:function ($$v) {_vm.$set(item.role, "id", $$v)},expression:"item.role.id"}})]}},{key:"item.assignedActions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAssignedActions(item.role.id))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"additional-row",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"additional-row-head-wrapper"},[_c('table',{staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Module")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Action")])])])]),_c('tbody',_vm._l((_vm.getRolePermissions(item.role.id)),function(role,i){return _c('tr',{key:("role-" + i)},[_c('td',[_vm._v(" "+_vm._s(role.split("||")[0])+" ")]),_c('td',[_vm._v(" "+_vm._s(role.split("||")[1])+" ")])])}),0)])])])]}}],null,true)})],1):_c('v-sheet',{attrs:{"color":"white"}},[_vm._v(" You are not allowed on this page... ")])}
var staticRenderFns = []

export { render, staticRenderFns }